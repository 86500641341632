.c_text_input_container{
    display: flex;
    width: 100%;
}
.c_text_input_value{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    padding: 14px 16px;
    color: #aaaaaa;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #e5e7eb;
}
.c_text_input_value_s{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    padding: 14px 16px;
    color: #aaaaaa;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #e5e7eb;
}
.c_text_input_value_have_content{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #e5e7eb;
}
.c_text_input_value_have_content_s{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #e5e7eb;
}
.c_text_input_value:focus{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border: solid 1px #017ef3;
}
.c_text_input_value_s:focus{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border: solid 1px #017ef3;
}
.c_text_input_value_error{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #ea3829;
}
.c_text_input_value_error:focus{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border: solid 1px #ea3829 !important;
}
.c_text_input_value_error_s{
    appearance: none;
    border-radius: 10px;
    width: 100%;
    padding: 14px 16px;
    color: #1b1b1e;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #ea3829;
}
.c_text_input_icon_span{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.c_text_input_icon{
    position: absolute;
    /* margin-right: 64px; */
}
.c_text_input_error_container{
    margin-top: 12px;
}
.c_text_input_failed_text{
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ea3829;
}