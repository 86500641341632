.dashboard_filter_container_t{
    opacity: 1 !important;
    width: 200px;
    display: flex;
    position:absolute;
    top: 26%;
    left: 60%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 0 auto;
    flex-direction: column;
    padding: 12px;
    z-index: 9999;
}
.dashboard_filter_container_e{
    opacity: 1 !important;
    width: 200px;
    display: flex;
    position:absolute;
    top: 26%;
    left: 76%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 0 auto;
    flex-direction: column;
    padding: 12px;
    z-index: 9999;
}
.dashboard_filter_close{
    width: 100%;
    display: flex;
    justify-content:space-between;
}
.item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    cursor:pointer;
}
.dashboard{
    padding: 37px 48px 0 48px;
}
.dashboard_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}
.dashboard_title_container{
    display: flex;
    gap: 20px;
    /* justify-content: center; */
    align-items: center;
    flex-direction: row;
}
.dashboard_header_title{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.dashboard_header_status_group{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.dashboard_header_date{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #6c6c6c;
}
.dashboard_header_status{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.dashboard_header_status_dot{
    width: 7px;
    height: 7px;
    background-color: #0047ff;
    border-radius: 50%;
}
.dashboard_header_status_dot_pending{
    width: 7px;
    height: 7px;
    background-color: #008F5D;
    border-radius: 50%;
}
.dashboard_header_status_dot_executing_complete{
    width: 7px;
    height: 7px;
    background-color: #7a797d;
    border-radius: 50%;
}
.dashboard_header_status_dot_incomplete_retry{
    width: 7px;
    height: 7px;
    background-color: #FFA500;
    border-radius: 50%;
}
.dashboard_header_status_dot_stopped{
    width: 7px;
    height: 7px;
    background-color: #EA3829;
    border-radius: 50%;
}
.dashboard_header_status_text{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #6c6c6c;
}
.dashboard_header_filter{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
}
.dashboard_header_filter_content{
    display: flex;
    background-color: #f6f6f8;
    border-radius: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #6c6c6c;
    cursor: pointer;
}
.dashboard_header_filter_clean{
    padding-left: 3px;
}
.dashboard_tablet_header{
    height: 48px;
    background-color: #f6f6f8;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "file frequency date hour travel-agent export-status";
    padding: 14px 48px 14px 12px;
    margin: 16px 0px 4px 0;
}
.dashboard_tablet_header p{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #6c6c6c;
}
.dashboard_tablet_item_pending{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard_tablet_item_pending p{
    height: 32px;
    border-radius: 5px;
    background-color: #cef8e0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #008f5d;
    padding: 12px;
}
.dashboard_tablet_item_executed_complete{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard_tablet_item_executed_complete p{
    height: 32px;
    border-radius: 5px;
    background-color: #d4d4d5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c6c6c;
    padding: 12px;
}
.dashboard_tablet_item_incomplete_retry{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard_tablet_item_incomplete_retry p{
    height: 32px;
    border-radius: 5px;
    gap: 5px;
    background-color: #ffa60077;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ea3829;
    padding: 12px;
}
.dashboard_tablet_item_stopped{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard_tablet_item_stopped p{
    height: 32px;
    border-radius: 5px;
    background-color: #ffebe7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ea3829;
    padding: 12px;
}
.dashboard_tablet_item_e{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard_tablet_item_r{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard_tablet_item_span{
   cursor: pointer;
}
.dashboard_tablet_item_e p{
    height: 32px;
    border-radius: 5px;
    background-color: #ffebe7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ea3829;
    padding: 12px;
}
.dashboard_tablet_item_r p{
    height: 32px;
    border-radius: 5px;
    background-color: #ffebe7;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #ea3829;
    padding: 12px;
}
.dashboard_tablet_item_pending Image{
   cursor: pointer;
}
.dashboard_tablet_movile{
    display: none;
}

@media (max-width: 600px) {
    .dashboard{
        padding: 32px 12px;
    }
    .dashboard_header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .dashboard_header_status_group{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .dashboard_tablet_movile{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 32px;
    }
    .dashboard_tablet_movile_items_group{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        background-color: #ededed;
    }
    .dashboard_tablet_movile_item{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        border-bottom: solid 1px #dedede;
        padding: 5px;
    }
    .dashboard_tablet_movile_item p{
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.2px;
        padding: 5px;
    }
    .dashboard_tablet_header_movile_a, .dashboard_tablet_header_movile_b, .dashboard_tablet_header_movile_c, .dashboard_tablet_header_movile_d, .dashboard_tablet_header_movile_e, .dashboard_tablet_header_movile_f {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.1px;
        color: #6c6c6c;
    }
    .dashboard_tablet_item_movile_f{
        display: flex;
        align-items: center;
        gap: 8px;
    }
  }
