.v_manage_passengers_create{
    padding: 32px 140px 64px 140px;
}
.v_manage_passengers_create_main{
    /* padding-right: 30px; */
}
.v_manage_passengers_create_title_group{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_manage_passengers_create_title{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_passengers_create_subtitle{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #6c6c6c;
}
.v_manage_passengers_create_group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding-top: 32px;
}
.v_manage_passengers_create_group_column_titles{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 48px;
}
.v_manage_passengers_create_group_column{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 48px;
    align-items: center;
}
.v_manage_passengers_create_field_title{
    width: 100%;
    height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #3498f5;
}
.v_manage_passengers_create_content_title{
    width: 100%;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_passengers_create_content_title_red{
    width: 100%;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ea3829;
}
.v_manage_passengers_create_content{
    width: 100% !important;
}
.v_manage_passengers_create_content_values{
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 3fr;
}
.v_manage_passengers_create_button_group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 22px;
}
.v_manage_passengers_create_format_error{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ea3829;
}
.v_manage_passengers_create_button{
    width: 192px;
}

@media (max-width: 900px) {
    .v_manage_passengers_create{
        padding: 20px;
    }
    .v_manage_passengers_create_group_column{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}