.v_data_report_filter_container_t{
    opacity: 1 !important;
    width: 200px;
    display: flex;
    position:absolute;
    top: 26%;
    left: 60%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 0 auto;
    flex-direction: column;
    padding: 12px;
    z-index: 9999;
}
.v_data_report_filter_container_e{
    opacity: 1 !important;
    width: 200px;
    display: flex;
    position:absolute;
    top: 26%;
    left: 76%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 0 auto;
    flex-direction: column;
    padding: 12px;
    z-index: 9999;
}
.v_data_report_filter_close{
    width: 100%;
    display: flex;
    justify-content:space-between;
}
.item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    cursor:pointer;
}
.v_data_report{
    padding: 32px 140px 64px 140px;
}
.v_data_report_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}
.v_data_report_header_filter{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
}
.v_data_report_header_filter_content{
    display: flex;
    background-color: #f6f6f8;
    border-radius: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #6c6c6c;
    cursor: pointer;
}
.v_data_report_header_filter_clean{
    padding-left: 3px;
}
.v_data_report_header_title{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_data_report_tablet_item_f{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.v_data_report_tablet_item_f p{
    height: 32px;
    border-radius: 5px;
    background-color: #cef8e0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #008f5d;
    padding: 12px;
}
.v_data_report_tablet_item_e{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.v_data_report_tablet_item_e p{
    height: 32px;
    border-radius: 5px;
    background-color: #ffebe7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ea3829;
    padding: 12px;
}
.v_data_report_tablet_item_r{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.v_data_report_tablet_item_r p{
    height: 32px;
    border-radius: 5px;
    background-color: #ffebe7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ea3829;
    padding: 12px;
}
.v_data_report_tablet_item_span{
    cursor: pointer;
 }

@media (max-width: 990px) {
    .v_data_report{
        padding: 32px 12px;
    }
    .v_data_report_header{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }
