.v_login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .v_login_form_container, .v_login_image_container{ */
.v_login_form_container{
    width: 552px;
    height: 724px;
}
.v_login_image_container {
    width: 762px;

    justify-content: center;
    align-items: center;
    align-content: center;
}
.v_login_form_container{
    padding: 32px 76px 32px 77px;
}
.v_login_logo_container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.v_login_logo{
    width: 112px;
    height: 88px;
}
.v_login_form{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 28px;
}
.v_login_form_title{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_login_form_input_group{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_login_form_input_group_f{
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
}
.v_login_form_label{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #1b1b1e;
}
.v_login_error_msg{
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-align: left;
    color: #ea3829;
}
.v_login_forgot_text{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: left;
    color: #7a797d;
}
.v_login_forgot_link_text{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: left;
    color: #017ef3;
}
.v_login_captcha{
    /* transform: scale(0.55);
    transform-origin: 0% */
}

@media (max-width: 1250px) {
    .v_login_form_container{
        padding: 17px;
    }
    .v_login_image_container{
        display: none;
    }
  }