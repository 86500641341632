.c_modal_background {
  width: 100%;  
  height: 100vh;
  position: fixed;
  opacity: 0.5;
  background-color: #1b1b1e;
  z-index: 99 !important;
}
.c_modal_container{
  height: auto;
  opacity: 1 !important;
  display: flex;
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);    
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  margin: 0 auto;
  margin-top: 0 auto;
  flex-direction: column;
  z-index: 999 !important;
}
.c_modal_header{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 16px 16px 0 32px;
}
.c_modal_group{
  padding: 0 32px 32px 32px;
}
.c_modal_title{
  height: 32px;
  align-items: flex-start;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1b1b1e;
}
.c_modal_information_group{
  display: flex;
  flex-direction: row;
  margin: 16px 0 24px 0;
}
.c_modal_information{
  width: 60%;
  height: 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
}
.c_modal_information_cancelable{
  width: 100%;
  height: 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
}
.c_moda_cancelable_btn_group{
  display: grid;
  grid-template-columns: 218px 234px;
  gap: 24px;
  margin-bottom: 32px;
}
.c_modal_information_create{
  width: 100%;
  height: 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
}
.c_modal_detele_group{
  width: 40%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #017ef3;
  cursor: pointer;
}
.c_modal_detele_text{
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #017ef3;
}
.c_modal_schedule_group{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}
.c_modal_schedule_text_input {
  width: 100%;
}
.c_modal_schedule_text{
  width: 100%;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #1b1b1e;
}
.c_modal_delete_icon{
  width: 22px;
  height: 24px;
  margin-right: 13px;
}
.c_modal_schedule_button_group{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2px 0 0 0;
  padding-top: 12px;
}
.c_modal_schedule_button{
  width: 168px !important;
}
.c_modal_schedule_button_create{
  width: 200px !important;
}