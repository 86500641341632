.c_modal_table_background {
  width: 100%;  
  height: 100vh;
  position: fixed;
  opacity: 0.5;
  background-color: #1b1b1e;
  z-index: 999 !important;;
}
.c_modal_table_container{
  opacity: 1 !important;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);    
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  margin: 0 auto;
  margin-top: 0 auto;
  flex-direction: column;
  padding: 0 32px 32px 32px;
  z-index: 9999 !important;
}
.c_modal_table_header{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 16px;
}
.c_modal_table_group{
  margin: 0 32px 16px 32px;
}
.c_modal_table_main_group{
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.c_modal_table_content_group_principal{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.c_modal_table_content_group_wicon{
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}
.c_modal_table_content_group{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.c_modal_table_content_group_format{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.c_modal_table_icon_success{
  width: 24px;
  height: 24px;
  margin-right: 12px;
  filter: invert(34%) sepia(83%) saturate(3681%) hue-rotate(194deg) brightness(100%) contrast(100%);
}
.c_modal_table_icon_failed{
  width: 24px;
  height: 24px;
  margin-right: 12px;
  filter: invert(26%) sepia(97%) saturate(2234%) hue-rotate(347deg) brightness(95%) contrast(93%);
}
.c_modal_table_title_container{
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}
.c_modal_table_title_container_format{
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}
.c_modal_table_title{
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1b1b1e;
}
.c_modal_table_subtitle{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
}
.v_coclis_create_form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 32px;
}
.v_coclis_create_form_group{
  width: 100%;
  display: flex;
  gap: 24px;
}
.v_coclis_create_input_group{
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.v_coclis_create_input_label{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #1b1b1e;
}
.v_coclis_create_data_form{
  height: 48px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  grid-template-areas: 
      "title title title title title";
  padding: 14px 24px 14px 12px;
  margin: 32px 0px 4px 0;
}
.v_coclis_create_data_form_title{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #3498f5;
}
.v_coclis_create_data_form_button{
  width: 82%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.c_modal_table_search_group{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.c_modal_table_subtitle{
  width: 100%;
  height: 32px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #6c6c6c;
}
.c_modal_table_forgot_email_group{
  width: 100%;
}
.c_modal_table_reset_email_group{
  width: 100%;
  display: grid;
  gap: 12px;
}
.c_modal_table_list_table{
  width: 100%;
}
.c_modal_table_error_msg{
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: left;
  color: #ea3829;
}
.c_modal_table_information_group{
  width: 100%;
  display: grid;
  grid-template-columns: 175px 5% 160px;
  gap: 5px;
}
.c_modal_table_information{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
  padding-top: 14px;
}
.c_modal_table_information_mult{
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
  padding-top: 14px;
}
.c_modal_table_information_extra{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  row-gap: 25px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1b1b1e;
  padding-top: 29px;
}
.c_modal_table_information_warning{
  width: 100%;
  height: 24px;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 120px 238px 190px;
  gap: 7px
}
.c_modal_table_container_box{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 16px;
}
.c_modal_table_box{
  width: 100%;
  padding: 16px 16px;
  border-radius: 5px;
  border: solid 1px #e5e7eb;
}
.c_moda_main_box{
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.c_modal_table_content_box{
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
}
.c_modal_table_file_container{
  width: 50%;
  padding: 8px 16px;
  border-radius: 10px;
  border: solid 1px #017ef3;;
}
.c_modal_table_file_name{
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #017ef3;
}
.c_modal_table_file_date{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: #1b1b1e;
}
.c_modal_table_recovery_container{
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.c_modal_table_file_recovery{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #017ef3;
  cursor: pointer;
}
.c_modal_table_data_container{
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 24px;
}
.c_modal_table_data_text_container{
  display: flex;
  gap: 5px;
  flex-direction: row;
}
.c_modal_table_data_text{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1b1b1e;
}
.c_modal_table_data_subtext{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6c6c;
}
.c_modal_table_information_extra_text{
  height: 25px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.c_modal_table_information_bold_text{
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #1b1b1e;
}
.c_modal_table_information_mod_text{
  height: 25px;
  display: flex;
  flex-direction: row;
  gap: 7px;
}
.c_modal_table_information_extra_icon_group{
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
}
.c_modal_table_information_extra_icon{
  width: 16px;
  height: 16px;
  filter: invert(26%) sepia(97%) saturate(2234%) hue-rotate(347deg) brightness(95%) contrast(93%);
}
.c_modal_table_information_text_secondary{
  height: 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ea3829;
}
.c_modal_table_information_text_primary{
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6c6c6c;
}
.c_modal_table_button_group{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  /* margin-top: 32px; */
  gap: 24px;
}
.c_modal_table_button_secondary{
  /* width: 116px !important; */
}
.c_modal_table_button_primary{
  width: 141px !important;
}

@media(max-width: 639px){
  .c_modal_table_information{
    margin-top: 30px;
  }
  .c_modal_table_information_mult{
    margin-top: 0;
  }
  .c_modal_table_information_group{
  grid-template-columns: 175px 60px;
  }
  .c_modal_table_information_warning{
  grid-template-columns: 270px ;
  margin-bottom: 40px;
  }
}