.v_error_500_container{
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.v_error_500_title_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.v_error_500_title{
    font-size: 120px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}