.v_manage_travel_agent_edit{
    padding: 32px 140px 64px 140px;
}
.v_manage_travel_agent_edit_title_group{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_manage_travel_agent_edit_title_subgroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.v_manage_travel_agent_edit_title{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_travel_agent_edit_subtitle{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #6c6c6c;
}
.v_manage_travel_agent_edit_form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    margin-bottom: 32px;
}
.v_manage_travel_agent_edit_form_group{
    width: 100%;
    display: flex;
    gap: 24px;
}
.v_manage_travel_agent_edit_input_group{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_manage_travel_agent_edit_input_label{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #1b1b1e;
}
.v_manage_travel_agent_edit_data_form{
    height: 48px;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
    grid-template-areas: 
        "title title title title title";
    padding: 14px 24px 14px 12px;
    margin: 32px 0px 4px 0;
}
.v_manage_travel_agent_edit_data_form_title{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #3498f5;
}
.v_manage_travel_agent_edit_data_form_item{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_travel_agent_edit_data_form_field_column{
    width: 30%;
    grid-area: title;
}
.v_manage_travel_agent_edit_data_form_content_column{
    width: 60%;
    grid-area: title;
}
.v_manage_travel_agent_edit_data_form_mandatory_column{
    width: 5%;
    grid-area: title;
}
.v_manage_travel_agent_edit_data_form_hide_field_column{
    width: 5%;
    grid-area: title;
}
.v_manage_travel_agent_edit_data_form_multiselect_field_column{
    width: 5%;
    grid-area: title;
}
.v_manage_travel_agent_edit_data_form_item_group{
    min-height: 30px;
    max-height: 50px;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
    grid-template-areas: 
        "col1 col2 col3 col4 col5";
    padding: 0px 24px 0px 12px;
    margin: 20px 0px 42px 0;
}
.v_manage_travel_agent_edit_data_form_field_column_item{
    grid-area: col1;
    display: flex;
    align-items: center;
}
.v_manage_travel_agent_edit_data_form_content_column_item{
    /* width: 300px; */
    grid-area: col2;
    padding-right: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.v_manage_travel_agent_edit_data_form_content_column_item_values{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
}
.v_manage_travel_agent_edit_data_form_mandatory_column_item{
    /* width: 50px; */
    grid-area: col3;
    display: flex;
    align-items: center;
    gap: 8px;
}
.v_manage_travel_agent_edit_data_form_hide_field_column_item{
    /* width: 50px; */
    grid-area: col4;
    display: flex;
    align-items: center;
    gap: 8px;
}
.v_manage_travel_agent_edit_data_form_multiselect_field_column_item{
    /* width: 50px; */
    grid-area: col5;
    display: flex;
    align-items: center;
    gap: 8px;
}
.v_manage_travel_agent_edit_data_form_button{
    width: 82%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1400px) {
    .v_manage_travel_agent_edit_data_form_content_column_item_values{
        display: grid;
        grid-template-columns:auto;
        gap: 5px;
    }
}
@media (max-width: 900px) {
    .v_manage_travel_agent_edit{
        align-items: center;
        padding: 32px;
    }
    .v_manage_travel_agent_edit_form_group{
       flex-direction: column;
    }
    .v_manage_travel_agent_edit_input_group{
        width: 100%;
    }
    .v_manage_travel_agent_edit_data_form{
        grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
        gap: 5px;
    }
    .v_manage_travel_agent_edit_data_form_item_group{
        min-height: 30px;
        grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
        gap: 10px;
    }
    .v_manage_travel_agent_edit_data_form_field_column_item p{
        font-size: 12px;
    }
    .v_manage_travel_agent_edit_data_form_content_column_item{
        padding-right: 0px;
    }
    .v_manage_travel_agent_edit_data_form_mandatory_column_item{
        font-size: 12px;
    }
    .v_manage_travel_agent_edit_data_form_hide_field_column_item{
        font-size: 12px;
    }
    .v_manage_travel_agent_edit_data_form_multiselect_field_column_item{
        font-size: 12px;
    }
  }
@media (max-width: 600px) {
    .v_manage_travel_agent_edit{
        align-items: center;
        padding: 32px 12px;
    }
    .v_manage_travel_agent_edit_title{
        font-size: 20px;
    }
    .v_manage_travel_agent_edit_subtitle{
        font-size: 14px;
    }
    .v_manage_travel_agent_edit_form_group{
       flex-direction: column;
    }
    .v_manage_travel_agent_edit_input_group{
        width: 100%;
    }
  }


