.v_manage_passengers_edited_success{
    width: 100%;
    height: 30px;
    background-color: #cef8e0;
    position: relative;
    animation: animation 2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
.v_manage_passengers_edited_failed{
    width: 100%;
    height: 30px;
    background-color: #ffebe7;
    position: relative;
    animation: animation 2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
#slide-in  {
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }
@keyframes animation {
    0% {
      opacity: 0;
      left: 800px;
    }

    100% {
      opacity: 1;
      left: 0;
    }
}
.v_manage_passengers_edited_group{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.v_manage_passengers_edited{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_passengers_edit{
    padding: 32px 140px 64px 140px;
}
.v_manage_passengers_edit_title_group{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_manage_passengers_edit_title_subgroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.v_manage_passengers_edit_title{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_passengers_edit_subtitle{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #6c6c6c;
}
.v_manage_passengers_edit_group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding-top: 32px;
}
.v_manage_passengers_edit_group_column_titles{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 48px;
}
.v_manage_passengers_edit_group_column{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 48px;
    align-items: center;
}
.v_manage_passengers_edit_field_title{
    width: 100%;
    height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #3498f5;
}
.v_manage_passengers_edit_content_title{
    width: 100%;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_passengers_edit_content_title_red{
    width: 100%;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ea3829;
}
.v_manage_passengers_edit_format_error{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ea3829;
}
.v_manage_passengers_edit_content{
    width: 100% !important;
}
.v_manage_passengers_edit_content_values{
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 3fr;
}
.v_manage_passengers_edit_button_group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 22px;
}
.v_manage_passengers_edit_button{
    width: 192px;
}
@media (max-width: 900px) {
    .v_manage_passengers_edit{
        padding: 20px;
    }
    .v_manage_passengers_edit_group_column{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}