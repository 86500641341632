.c_side_menu{
    width: 264px;
    height: auto;
    min-height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items:flex-start;
}
.c_side_menu_group{
    width: 264px;
    min-height: 100vh;
    display: flex;
    position: sticky;
    top: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 64px 24px;
}
.c_side_menu_group_top{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.c_side_menu_logo{
    width: 112px;
    height: 88px;
}
.c_side_menu_items{
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 40px;
}
.c_side_menu_item{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 12px;
}
.c_side_menu_item p{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7a797d;
}
.c_side_menu_item_selected{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background-color: #f2f9fe;
    padding: 9px 12px;
}
.c_side_menu_item_selected p{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #017ef3;
}
.c_side_menu_icon{
    width: 24px;
    height: 24px;
}
.c_side_menu_group_down{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.c_side_menu_group_down_image{
    margin-bottom: 24px;
}
.c_side_menu_group_down p{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #7a797d;
}
.c_side_menu_group_down_log_out_group{
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;

}

@media (max-width: 1250px) {
    .c_side_menu{
        width: 64px;
    }
    .c_side_menu_group{
        width: 64px;
        padding: 10px;
    }
    .c_side_menu_logo{
       display: none;
    }
    .c_side_menu_item p{
        display: none;
    }
    .c_side_menu_item_selected p{
        display: none;
    }
    .c_side_menu_group_down_image{
        display: none;
    }
    .c_side_menu_group_down p{
        display: none;
    }
  }