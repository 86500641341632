.c_header{
    width: 100%;
    height: 88px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 24px 48px 24px 24px;
}
.c_header_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 49px
}
.c_header_back_button{
    cursor: pointer;
}
.c_header_container_b{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 49px
}
.c_header_user_options_group{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 49px;
}
.c_header_user_group{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.c_header_user{
    display: flex;
    flex-direction: column;
}
.c_header_user_roll{
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.25px;
    color: #017ef3;
}
.c_header_user_name_group{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.c_header_user_name{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.c_header_dp_group{
    /* width: 300px; */
    margin-top: 20px;
    /* justify-content: flex-end;
    align-items: flex-end; */
}

@media (max-width: 700px) {
    .c_header{
        padding: 24px;
    }
  }