.c_user_drop_selected{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.c_user_drop_selected p {
    padding-top: 3px;
}
.c_user_drop_avatar{
    width: 40px;
    height: 40px;
    border-radius: 25px;
}
.c_user_drop_container_options{
    width: 100%;
    display: flex;
}
.c_user_main_drop_options {
    width: 100%;
    position: relative;
}
.c_user_drop_options{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    border: solid 1px #b1afb5;
    background-color: #fff;
    margin-top: 6px;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 99999;
}
.c_user_drop_user{
    display: flex;
    flex-direction: column;
}
.c_user_drop_roll{
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.25px;
    color: #017ef3;
}
.c_user_drop_name_group{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.c_user_drop_name{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.c_user_drop_options_item{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 18px;
}
.c_user_drop_options_item p{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7a797d;
    padding-top: 3px;
}