.c_dropdown_selected{
    width: 100%;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: solid 1px #e8eaed;
    border-radius: 10px;
    padding: 14px 16px;
}
.c_dropdown_selected_s{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: solid 1px #e8eaed;
    border-radius: 10px;
    padding: 14px 16px;
}
.c_dropdown_select{
    width: 100%;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: solid 1px #e8eaed;
    border-radius: 10px;
    padding: 14px 16px;
}
.c_dropdown_select_h{
    width: 100%;
    /* height: 48px; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: solid 1px #e8eaed;
    border-radius: 10px;
    padding: 14px 16px;
}
.c_dropdown_selected_f{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: solid 1px #ea3829;
    border-radius: 10px;
    padding: 14px 16px;
}
.c_dropdown_selected p {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7a797d;
}
.c_dropdown_select p {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1b1b1e;
}
.c_dropdown_select_h p {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1b1b1e;
}
.c_dropdown_selected_s p {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7a797d;
}
.c_dropdown_selected_f p {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ea3829;
}
.c_dropdown_icon{
    width: 20px;
    height: 20px;
}
.c_dropwdown_options_group{
    width: 100%;
    position:relative;
}
.c_dropdown_options{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    border-radius: 10px;
    border: solid 1px #6c6c6c;
    background-color: #fff;
    margin-top: 6px;
    padding: 0 0 9px 0;
    z-index: 999;
}
.c_dropdown_options_multiselect{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    border-radius: 10px;
    border: solid 1px #6c6c6c;
    background-color: #fff;
    margin-top: 6px;
    z-index: 999;
}
.c_dropdown_options_finder{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 10px;
    border: solid 1px #6c6c6c;
    background-color: #fff;
    margin-top: 16px;
    padding: 24px 0 5px 0;
    z-index: 9999;
}
.c_dropdown_options_group{
    margin: 16px 0;
    cursor: pointer;
}
.c_dropdown_options_multiselect_group{
    margin: 16px 0;
    cursor: pointer;
    gap: 24px;
    display: flex;
    flex-direction: column;
}
.c_dropdown_options_group_scroll{
    margin: 16px 0 0 0;
    max-height: 360px;
    cursor: pointer;
    overflow:scroll;
    overflow-x:hidden;
}
.c_dropdown_option_selected{
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e6f2fe;
}
.c_dropdown_option_notselected{
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}
.c_dropdown_option_multiselect{
    width: 100%;
    /* height: 36px; */
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 24px;
    gap: 14px;
}
.c_drowpdown_text{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1b1b1e;
    margin: 0 31px;
}
.c_drowpdown_text_multiselect{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1b1b1e;
    gap: 12px;
}
.c_dropdown_input_main{
    margin: 0 24px;
}
.c_dropdown_input_group{
    width: 100%;
    height: 40px;
    gap: 12px;
    border-radius: 20px;
    border: solid 1px #e5e7eb;
    display: flex;
    align-items: center;
    padding: 10px 24px;
}
.c_dropdown_input_group:focus-within {
    width: 100%;
    height: 40px;
    gap: 12px;
    border-radius: 20px;
    border: solid 1px #aaaaaa;
    display: flex;
    align-items: center;
    outline: none !important;
    padding: 10px 24px;
}
.c_dropdown_input_group:active {
    width: 100%;
    height: 40px;
    gap: 12px;
    border-radius: 20px;
    border: solid 1px #017ef3;
    display: flex;
    align-items: center;
    outline: none !important;
    padding: 10px 24px;
}
.c_dropdown_input_img{
    width: 24px;
    height: 24px;
}
.c_dropdown_input_finder{
    width: 100%;
    border: 1px solid transparent !important;
    color: '#aaaaaa';
    font-size: 14px;
}
.c_dropdown_input_finder:focus{
    outline: none !important;
}
.c_dropdown_options_item{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}
.c_dropdown_options_item p{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7a797d;
    padding-top: 3px;
}
.c_dropdown_button_group{
    width: 100%;
    padding-top: 12px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 31px;
}
.c_dropdown_button{
    width: 101px !important;
}