.c_language_drop_selected{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.c_language_drop_selected p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7a797d;
    padding-top: 3px;
}
.c_language_drop_options{
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    border: solid 1px #b1afb5;
    background-color: #fff;
    margin-top: 6px;
    padding: 8px;
}
.c_language_drop_options_item{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}
.c_language_drop_options_item p{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7a797d;
    padding-top: 3px;
}