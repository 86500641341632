
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

* {
    font-family: 'Poppins', sans-serif;

    .MuiButton-contained {
        text-transform: none !important;
    }
    .mui-datagrid .mui-datagrid-row:not(.mui-datagrid-row-header) {
        border-bottom: none;
        border-color: white;
    }
    .MuiButton-outlined {
        padding: 8px 16px !important;
    }
    
}