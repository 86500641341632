.guZdik {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 120px !important;
}
.c_drag_drop_container {
    width: 100%;

    height: 120px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 2px dashed rgb(6, 88, 194);
    border-radius: 5px;
    padding: 8px 16px 8px 8px;
    cursor: pointer;
    font-size: 12px;
    color: rgb(102, 102, 102);
}
.c_drag_drop_left_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.c_drag_drop_left_container > input {
    display: block;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}
.c_drag_drop_msg {
    color: rgb(102, 102, 102);
}
.c_drag_drop_error_msg {
    color: red;
}