.v_manage_passengers_list_loading{
    width: 100%;
    height: 30px;
    background-color: #f2f9fe;
    position: relative;
    animation: animation 2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
#slide-in  {
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }
@keyframes animation {
    0% {
      opacity: 0;
      left: 800px;
    }

    100% {
      opacity: 1;
      left: 0;
    }
}
.v_manage_passengers_list_uploaded{
    width: 100%;
    height: 30px;
    background-color: #cef8e0;
    position: relative;
    animation: animation 2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
#slide-in  {
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }
@keyframes animation {
    0% {
      opacity: 0;
      left: 800px;
    }

    100% {
      opacity: 1;
      left: 0;
    }
}
.v_manage_passengers_list_notuploaded{
    width: 100%;
    height: 30px;
    background-color: #ffebe7;
    position: relative;
    animation: animation 2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
#slide-in  {
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }
@keyframes animation {
    0% {
      opacity: 0;
      left: 800px;
    }

    100% {
      opacity: 1;
      left: 0;
    }
}
.v_manage_passengers_list_icon_rotating{
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.v_manage_passengers_list_group_response{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.v_manage_passengers_list_response_text{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manasge_passenger_list_filter_container_t{
    opacity: 1 !important;
    width: 200px;
    display: flex;
    position:absolute;
    top: 26%;
    left: 60%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 0 auto;
    flex-direction: column;
    padding: 12px;
    z-index: 9999;
}
.v_manasge_passenger_list_filter_container_e{
    opacity: 1 !important;
    width: 200px;
    display: flex;
    position:absolute;
    top: 59%;
    left: 82%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: 0 auto;
    margin-top: 0 auto;
    flex-direction: column;
    padding: 12px;
    z-index: 9999;
}
.v_manage_passenger_list_download_group{
    width: 100%;
    display: grid;
    grid-template-columns: 244px 151px 26% 22%;
    gap: 24px;
}
.v_manasge_passenger_list_filter_close{
    width: 100%;
    display: flex;
    justify-content:space-between;
}
.v_manasge_passenger_list_file{
    width: 100%;
    height: 32px;
    margin: 12px 0;
    padding: 0 12px;
    border-radius: 5px;
    background-image: linear-gradient(to left, #e5e7eb 100%, #f6f6f8 0%);
}
.item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    cursor:pointer;
}
.v_manage_passenger_list{
    width: 100%;
    padding: 37px 48px 0 48px;
}
.v_manage_passenger_list_search_group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 52px;
}
.v_manage_passenger_list_search_agent{
    width: 436px;
    display: flex;
    gap: 12px;
    flex-direction: column;
}
.v_manage_passenger_list_search_arragner{
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: column;
}
.v_manage_passenger_list_search_arragner_grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 16px;
}
.v_manage_passenger_list_search_arragner_search_button{
    width: 113px;
}
.v_manasge_passenger_list_header{
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 22px;
}
.v_manasge_passenger_list_header_group{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.v_manasge_passenger_list_header_title{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manasge_passenger_list_table{
    width: 100%;
}
.v_manasge_passenger_list_tablet_item_f{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.v_manasge_passenger_list_tablet_item_f p{
    height: 32px;
    border-radius: 5px;
    background-color: #cef8e0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #008f5d;
    padding: 12px;
}
.v_manasge_passenger_list_tablet_item_e{
    grid-area: export-status;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.v_manasge_passenger_list_tablet_item_e p{
    height: 32px;
    border-radius: 5px;
    background-color: #ffebe7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ea3829;
    padding: 12px;
}
.v_manasge_passenger_item_e{
    margin-right: 8px;
}

@media(max-width: 1500px){
    .v_manage_passenger_list_download_group{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 12px;
    }
}
@media (max-width: 1040px) {
    .v_manage_passenger_list{
        width: 100%;
        padding: 20px;
    }
}
@media (max-width: 900px) {
    .v_manasge_passenger_list_header{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .v_manage_passenger_list_search_arragner_grid{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 16px;
    }
    .v_manage_passenger_list_download_group{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        gap: 12px;
    }
}
