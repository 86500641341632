.c_button_animation_container{
    height: 100%;
    display: flex;
    border-radius:5px;
    justify-content: center;
    align-items: center;
}
.c_button_animation_dot {
    display: inline-block;
    margin: 3px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
}

.c_button_animation_container .c_button_animation_dot:nth-last-child(1) {
    animation: jumpingAnimation 1.1s 0.5s linear infinite;
}
.c_button_animation_container .c_button_animation_dot:nth-last-child(2) {
    animation: jumpingAnimation 1.1s 0.2s linear infinite;
}
.c_button_animation_container .c_button_animation_dot:nth-last-child(3) {
    animation: jumpingAnimation 1.1s 0s linear infinite;
}

@keyframes jumpingAnimation {
    0% {
        transform: translate(0, 0);
        opacity: 0.5; 
    }
    16% {
        transform: translate(0, -7px);
        opacity: 1; 
    }
    33% {
        transform: translate(0, 0);
        opacity: 0.5; 
    }
    100% {
        transform: translate(0, 0);
        opacity: 0.5; 
    }
}