.v_coclis_create{
    padding: 32px 140px 64px 140px;
}
.v_coclis_create_title_group{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_coclis_create_title{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_coclis_create_subtitle{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #6c6c6c;
}
.v_coclis_create_form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    margin-bottom: 32px;
}
.v_coclis_create_form_group{
    width: 100%;
    display: flex;
    gap: 24px;
}
.v_coclis_create_input_group{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_coclis_create_input_label{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #1b1b1e;
}
.v_coclis_create_data_form_title{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #3498f5;
}
.v_coclis_create_data_form_button{
    width: 82%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 900px) {
    .v_coclis_create{
        align-items: center;
        padding: 32px;
    }
    .v_coclis_create_form_group{
       flex-direction: column;
    }
    .v_coclis_create_input_group{
        width: 100%;
    }
  }
@media (max-width: 600px) {
    .v_coclis_create{
        align-items: center;
        padding: 32px 12px;
    }
    .v_coclis_create_title{
        font-size: 20px;
    }
    .v_coclis_create_subtitle{
        font-size: 14px;
    }
    .v_coclis_create_form_group{
       flex-direction: column;
    }
    .v_coclis_create_input_group{
        width: 100%;
    }
  }


