.v_download_template{
    width: 100%;
    padding: 32px 140px 64px 140px;
}
.v_download_template_group{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-top: 24px;
}
.v_download_template_title{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #1b1b1e;
}

@media (max-width: 900px) {
    .v_download_template{
        align-items: center;
        padding: 32px 12px;
    }
  }
@media (max-width: 600px) {
    .v_download_template{
        align-items: center;
        padding: 32px 12px;
    }
    .v_download_template_group{
        width: 100%;
    }
  }


