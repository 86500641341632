.v_manage_passengers{
    width: 100%;
    padding: 32px 140px 64px 140px;
}
.v_manage_passengers_title_group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.v_manage_passengers_title{
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1b1e;
}
.v_manage_passengers_subtitle{
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #6c6c6c;
}
.v_manage_passengers_group{
    width: 435px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-top: 24px;
}
.v_manage_passengers_title_finder{
    /* width: 219px; */
    /* height: 20px; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #1b1b1e;
}

@media (max-width: 900px) {
    .v_manage_passengers{
        align-items: center;
        padding: 32px 12px;
    }
  }
@media (max-width: 600px) {
    .v_manage_passengers{
        align-items: center;
        padding: 32px 12px;
    }
    .v_manage_passengers_group{
        width: 100%;
    }
  }


